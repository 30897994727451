<template>
  <div class="home">
    <!-- Materiales -->
    <component :is="currentMaterial" />
    <!--  -->
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex'
import Aquasol from '@/components/home/Aquasol.vue'
import Canvas from '@/components/home/Canvas.vue'

export default {
  name: 'home',
  components: {
    Aquasol,
    Canvas,
  },
  computed: {
    ...mapGetters(['navMaterial']),
    currentMaterial: function () {
      if (this.navMaterial.title === 'Aquasol') return Aquasol
      if (this.navMaterial.title === 'Lona') return Canvas
      else return null
    },
  },
}
</script>
