<template>
  <div class="home">
    <div class="home-content">
      <p>{{ title }}</p>
      <div class="form">
        <SwitchButtons :options="options" :clickSwitch="clickSwitchOptions" />
        <SwitchButtons :options="shapes" :clickSwitch="clickSwitchShapes" />
        <!-- <p class="subtitle" v-if="options[1].selected" >Área</p> -->
        <p class="subtitle">Ancho</p>
        <TextField
          type="number"
          placeholder="0.00m"
          maxlength="6"
          :value="inputs[0]"
          v-model="inputs[0]"
        />
        <p class="subtitle">Largo</p>
        <TextField
          type="number"
          placeholder="0.00m"
          maxlength="6"
          :value="inputs[1]"
          v-model="inputs[1]"
        />
        <!-- Dimensiones con extras regulares -->
        <div v-if="options[0].selected">
          <p class="subtitle">Extra</p>
          <TextField
            type="number"
            placeholder="0.00m"
            maxlength="6"
            :value="inputs[1]"
            v-model="inputs[2]"
          />
        </div>
        <!-- Dimensiones con extras personalizados -->
        <div v-if="options[1].selected">
          <p class="subtitle">Extras por Lado</p>
          <div class="pool">
            <div class="square">
              <!-- Señales de los lados -->
              <span class="letters" style="top: -18px; left: 100px">A</span>
              <span class="letters" style="right: -16px; top: 15px">B</span>
              <span class="letters" style="bottom: -23px; left: 100px">C</span>
              <span class="letters" style="left: -12px; top: 15px">D</span>
              <!-- Señales de dirección -->
              <span
                class="direction"
                style="left: 50px; top: 18px; transform: rotate(-90deg)"
                >Ancho</span
              >
              <span class="direction" style="left: 90px; top: 18px">Largo</span>
            </div>
          </div>
          <div class="columns">
            <div>
              <div class="cell">
                <p class="subtitle">A</p>
                <TextField
                  type="number"
                  placeholder="0.00m"
                  maxlength="6"
                  :value="inputs[1]"
                  v-model="inputs[3][0]"
                />
              </div>
              <div class="cell">
                <p class="subtitle">C</p>
                <TextField
                  type="number"
                  placeholder="0.00m"
                  maxlength="6"
                  :value="inputs[1]"
                  v-model="inputs[3][2]"
                />
              </div>
            </div>
            <div>
              <div class="cell">
                <p class="subtitle">B</p>
                <TextField
                  type="number"
                  placeholder="0.00m"
                  maxlength="6"
                  :value="inputs[1]"
                  v-model="inputs[3][1]"
                />
              </div>
              <div class="cell">
                <p class="subtitle">D</p>
                <TextField
                  type="number"
                  placeholder="0.00m"
                  maxlength="6"
                  :value="inputs[1]"
                  v-model="inputs[3][3]"
                />
              </div>
            </div>
          </div>
        </div>
        <ButtonDefault value="COTIZAR" :clickEvent="calcQuotation" />
        <!-- Checkbox IGV -->
        <!-- <template>
                    <div class="chk-igv">
                        <input type="checkbox" v-model="includeIGV" >
                        <span @click="changeIncludeIGV">Incluir IGV</span>
                    </div>
                </template> -->
      </div>
      <div class="results">
        <CoverResult :extraInfo="extraInfo" :includeIGV="includeIGV" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import SwitchButtons from '@/components/elements/SwitchButtons.vue'
import TextField from '@/components/elements/TextField.vue'
import ButtonDefault from '@/components/elements/ButtonDefault.vue'
import CoverResult from '@/components/home/CoverResult.vue'

export default {
  name: 'Canvas',
  components: {
    SwitchButtons,
    TextField,
    ButtonDefault,
    CoverResult,
  },
  data: () => ({
    title: 'Cobertores de lona',
    options: [
      {
        id: 1,
        value: 'Dimensiones',
        selected: true,
      },
      {
        id: 2,
        value: 'Personalizado',
        selected: false,
      },
    ],
    shapes: [
      {
        id: 1,
        value: 'Rectangular',
        selected: true,
      },
      {
        id: 2,
        value: 'Con Diseño',
        selected: false,
      },
    ],
    inputs: [null, null, '0.00', ['0.00', '0.00', '0.00', '0.00']], // 0: ancho, 1: largo, 2: extra, 3: lados personalizados
    extraInfo: null,
  }),
  computed: {
    ...mapGetters(['includeIGV']),
  },
  methods: {
    ...mapActions(['setDimension']),
    ...mapMutations(['SET_INCLUDE_IGV']),
    /**
     * Cambiar el valor de IGV Incluido
     */
    changeIncludeIGV() {
      this.SET_INCLUDE_IGV(!this.includeIGV)
    },
    /**
     * @param {Number} índice del switch de opciones de cobertor termico
     * @example
     * clickSwitchOptions(0)
     */
    clickSwitchOptions(optionIndex) {
      this.unselectAllSwitch(this.options)
      this.options[optionIndex].selected = true
    },
    /**
     * @param {Number} índice del switch de formas
     * @example
     * clickSwitchShapes(0)
     */
    clickSwitchShapes(optionIndex) {
      this.unselectAllSwitch(this.shapes)
      this.shapes[optionIndex].selected = true
    },
    /**
     * Deselecciona el switch
     * @param {Object[]} switchItems
     * @example
     * unselectAllSwitch([{id:1,selected:true,value:'Item 1'},{id:2,selected:false,value:'Item 2'}])
     */
    unselectAllSwitch(switchItems) {
      switchItems.forEach((option) => {
        option.selected = false
      })
    },
    // Lógica
    async calcQuotation() {
      // Por Dimensiones
      if (this.options[0].selected) {
        if (!this.inputs[0] || !this.inputs[1])
          return this.showAlert('Completa todos los campos')

        const extra = parseFloat(this.inputs[2])
        const height = parseFloat(this.inputs[1])
        const width = parseFloat(this.inputs[0])
        const area = width * height
        const custom = false
        const shape = this.shapes[0].selected ? 'RECT' : 'ANYWAY' // Por rectangular o diseño
        const payload = { width, height, extra, area, custom, shape }
        if (extra > 0)
          this.extraInfo = `Se agregaron ${extra * 2 * 100}cm a dos lados`
        else this.extraInfo = null
        // if( extra > 0 ) this.extraInfo = `Se agregaron ${extra * 2}m a dos lados`
        await this.setDimension(payload)
      }
      // Por Área
      else if (this.options[1].selected) {
        if (
          !this.inputs[0] ||
          !this.inputs[1] ||
          !this.inputs[3].every((n) => n)
        )
          return this.showAlert('Completa todos los campos')

        const extras = this.inputs[3].map((n) => parseFloat(n))
        const width = parseFloat(this.inputs[0])
        const height = parseFloat(this.inputs[1])
        const area = width * height
        const custom = true
        const shape = this.shapes[0].selected ? 'RECT' : 'ANYWAY' // Por rectangular o diseño
        const payload = { width, height, extras, area, custom, shape }
        if (extras.some((n) => n > 0)) {
          let sites = ''
          for (let extra of extras) {
            sites += `${extra * 100}cm `
          }
          this.extraInfo = `Se agregaron ${sites.trim()} a los lados`
        }
        await this.setDimension(payload)
      }
    },
    async showAlert(message) {
      alert(message)
    },
  },
}
</script>
<style lang="sass" scoped>
.home
    &-content
        p
            text-align: center
            color: #939393
        .form
            margin: 0 auto
            width: min-content
            .subtitle
                color: #444444
            .textfield
                width: calc(100% - 16px)
            .button-default
                width: 100%
                margin: 28px 0
                // margin: 28px 0 8px 0
            .chk-igv
                margin: 0 auto
                width: fit-content
                margin-bottom: 20px
            .pool
                position: relative
                height: 50px
                margin: 25px 0
                width: 100%
                .square
                    border: 2px solid #000
                    height: 50px
                    width: 100%
                    background-color: #00bcd41a
                    span
                        position: absolute
                        &.letters
                            color: #C23616
                        &.direction
                            color: #318CE7
            .columns
                display: flex
                div
                    width: 90%
        .results
            background: #eee
</style>
