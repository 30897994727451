<template>
  <div class="button-default">
    <button @click="clickEvent">{{ value }}</button>
  </div>
</template>

<script>
export default {
  name: 'ButtonDefault',
  props: {
    value: { type: String, required: true },
    clickEvent: { type: Function },
  },
}
</script>

<style lang="sass" scoped>
@import '@/sass/global.sass'

.button-default
    button
        background: $econdary-color
        padding: 8px 8px
        border: none
        color: #fff
        border-radius: 8px
        width: 100%
        cursor: pointer
</style>
