<template>
  <div class="price-text">
    <div class="price-text-content">
      <img src="../../assets/price-tag.png" alt="Prices" />
      <span
        >{{ productPrice.material }}: {{ productPrice.badge
        }}{{ productPrice.symbol }}.{{
          (productPrice.price * taxIGV).toFixed(2)
        }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'PriceText',
  props: {
    productPrice: { type: Object },
    material: { type: String },
    badge: { type: String },
    symbol: { type: String },
    price: { type: Number },
    taxIGV: { type: Number },
  },
}
</script>

<style lang="sass" scoped>
$break-large: 460px

.price-text
  &-content
    display: flex
    align-items: center
    margin: 5px
    span
      @media screen and (max-width: $break-large)
        font-size: 14px
    img
      width: 16px
</style>
