<template>
  <div class="switch-button">
    <div class="switch-button-content">
      <button
        v-for="(option, index) in options"
        :key="'option' + option.id"
        :class="{ selected: option.selected }"
        @click="clickSwitch(index)"
      >
        {{ option.value }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwitchButtons',
  props: {
    options: { type: Array, required: true },
    clickSwitch: { type: Function },
  },
}
</script>

<style lang="sass" scoped>
@import '@/sass/global.sass'

.switch-button
    &-content
        background: rgba(20, 21, 24, 0.76)
        padding: 5px
        width: max-content
        button
            border: none
            color: #6D6D6D
            background: transparent
            padding: 4px 8px
            border-radius: 4px
            width: 100px
            cursor: pointer
        .selected
            color: #fff
            background: $primary-color
</style>
