<template>
  <div class="textfield">
    <input
      @input="$emit('input', $event.target.value)"
      :type="type"
      :value="value"
      :placeholder="placeholder"
      :maxlength="maxlength"
    />
  </div>
</template>

<script>
export default {
  name: 'TextField',
  props: {
    type: { type: String, required: true },
    value: { type: String },
    placeholder: { type: String },
    maxlength: { type: String },
  },
}
</script>

<style lang="sass" scoped>
@import '@/sass/global.sass'

.textfield
    input
        outline: none
        border: none
        background: #fff
        padding: 8px
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1)
        text-align: center
        color: $text-color-dark
        width: 100%
        &::placeholder
            color: #C4C4C4
</style>
