<template>
  <div class="home-result">
    <div class="home-result-content">
      <!-- Cabecera para circulares -->
      <div v-if="shape === 'CIRCLE'" class="block">
        <div>
          <span>Diámetro: {{ diameter.toFixed(2) }}m</span>
          <span>Radio: {{ (diameter / 2).toFixed(2) }}m</span>
        </div>
        <div>
          <span>{{ titleArea }}: {{ area.toFixed(2) }}m<sup>2</sup></span>
        </div>
        <div v-if="productsPrice.length > 0">
          <span class="type-pool">{{ productsPrice[0].type_pool }}</span>
        </div>
        <div>
          <span class="type-pool ligth">{{
            includeIGV ? 'Incluye IGV' : 'Los precios NO incluyen IGV'
          }}</span>
        </div>
      </div>
      <!-- Cabecera para rectangulares -->
      <div v-else-if="productsPrice.length" class="block">
        <div v-show="!custom || productsPrice[0].id === '2-canvas'">
          <span>Ancho: {{ width }}m</span>
          <span>Largo: {{ height }}m</span>
        </div>
        <div>
          <span>{{ titleArea }}: {{ area.toFixed(2) }}m<sup>2</sup></span>
        </div>
        <div v-if="productsPrice.length > 0">
          <span class="type-pool">{{ productsPrice[0].type_pool }}</span>
        </div>
        <div v-show="extraInfo">
          <span class="type-pool dark">{{ extraInfo }}</span>
        </div>
        <div v-if="visibleExtraExpanded">
          <span>Área expandida: {{ areaExpanded }}m<sup>2</sup></span>
        </div>
        <div>
          <span class="type-pool ligth">{{
            includeIGV ? 'Incluye IGV' : 'Los precios NO incluyen IGV'
          }}</span>
        </div>
      </div>
      <!-- Resultado de precios -->
      <div class="block" v-if="productsPrice.length > 0">
        <hr />
        <div class="line">
          <PriceText
            v-for="productPrice in productsPrice"
            :key="'price' + productPrice.id"
            :productPrice="productPrice"
            :taxIGV="taxIGV"
          />
        </div>
        <span class="details">
          <span>Precios x m<sup>2</sup> : </span>
          <span
            v-for="(productPrice, index) in productsPrice"
            :key="'baseprice' + productPrice.id"
          >
            {{ productPrice.material }}: {{ productPrice.symbol }}.{{
              (productPrice.base_price * taxIGV).toFixed(2)
            }}
            <span v-show="index == 0"> - </span>
          </span>
        </span>
      </div>
      <!-- Resultado de precios con descuento -->
      <div class="block" v-if="productsPriceOff.length > 0">
        <hr />
        <span class="details color-primary"
          >{{ productsPriceOff[0].off }}% Desc</span
        >
        <div class="line">
          <PriceText
            v-for="productPriceOff in productsPriceOff"
            :key="'priceOff' + productPriceOff.id"
            :productPrice="productPriceOff"
            :taxIGV="taxIGV"
          />
        </div>
        <span class="details">
          <span>Precios x m<sup>2</sup> : </span>
          <span
            v-for="(productPriceOff, index) in productsPriceOff"
            :key="'baseprice' + productPriceOff.id"
          >
            {{ productPriceOff.material }}: {{ productPriceOff.symbol }}.{{
              (productPriceOff.base_price * taxIGV).toFixed(2)
            }}
            <span v-show="index == 0"> - </span>
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PriceText from '@/components/elements/PriceText.vue'

export default {
  name: 'CoverResult',
  props: {
    extraInfo: { type: String },
    includeIGV: { type: Boolean },
  },
  components: {
    PriceText,
  },
  computed: {
    ...mapGetters([
      'width',
      'height',
      'extra',
      'extras',
      'area',
      'diameter',
      'areaExpanded',
      'custom',
      'productsPrice',
      'productsPriceOff',
      'shape',
      'igv',
    ]),
    taxIGV() {
      if (!this.includeIGV) return 1
      return 1 + this.igv
    },
    titleArea() {
      if (!this.productsPrice.length) return 'Área'
      if (this.productsPrice[0].id === '2-canvas') {
        if (!this.custom) {
          if (this.extra) return 'Área original'
        } else {
          if (this.extras.some((n) => n > 0)) return 'Área original'
        }
      }
      return 'Área'
    },
    visibleExtraExpanded() {
      if (!this.custom) {
        return this.extra > 0
      } else {
        return this.extras.some((n) => n > 0)
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import '@/sass/global.sass'
$break-large: 460px
$padding-default: 10px

.home-result
    width: 100%
    margin: 0 auto
    &-content
        padding: 22px
        @media screen and (max-width: $break-large)
            padding: $padding-default
        .block
            text-align: center
            padding: $padding-default
            width: calc(100% - #{$padding-default * 2})
            .line
                display: flex
                flex-wrap: wrap
                justify-content: center
                // width: calc(100% - #{$padding-default * 2})
            hr
            .details
                font-size: 10px
                line-height: 8px
                color: #939393
            .color-primary
                color: $primary-color
            div
                span
                    padding: $padding-default $padding-default
        .type-pool
            color: $primary-color
            font-size: 12px
            &.dark
                color: #2c3e50
            &.ligth
                color: #939393
</style>
