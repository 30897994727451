<template>
  <div class="home">
    <div class="home-content">
      <p>{{ title }}</p>
      <div class="form">
        <SwitchButtons :options="options" :clickSwitch="clickSwitchOptions" />
        <SwitchButtons
          v-if="options[0].selected"
          :options="shapes"
          :clickSwitch="clickSwitchShapes"
        />
        <div v-if="shapes[0].selected">
          <p class="subtitle" v-if="options[1].selected">Área</p>
          <p class="subtitle" v-if="options[0].selected">Ancho</p>
          <TextField
            type="number"
            placeholder="0.00m"
            maxlength="6"
            :value="inputs[0]"
            v-model="inputs[0]"
          />
          <p class="subtitle" v-if="options[0].selected">Largo</p>
          <TextField
            v-if="options[0].selected"
            type="number"
            placeholder="0.00m"
            maxlength="6"
            :value="inputs[1]"
            v-model="inputs[1]"
          />
        </div>
        <div v-else>
          <p class="subtitle">Diámetro</p>
          <TextField
            v-if="options[0].selected"
            type="number"
            placeholder="0.00m"
            maxlength="6"
            :value="inputs[0]"
            v-model="inputs[0]"
          />
        </div>
        <ButtonDefault value="COTIZAR" :clickEvent="calcQuotation" />
        <!-- Checkbox IGV -->
        <!-- <template>
                    <div class="chk-igv">
                        <input type="checkbox" v-model="includeIGV" >
                        <span @click="changeIncludeIGV">Incluir IGV</span>
                    </div>
                </template> -->
      </div>
      <div class="results">
        <CoverResult :includeIGV="includeIGV" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import SwitchButtons from '@/components/elements/SwitchButtons.vue'
import TextField from '@/components/elements/TextField.vue'
import ButtonDefault from '@/components/elements/ButtonDefault.vue'
import CoverResult from '@/components/home/CoverResult.vue'

export default {
  name: 'Aquasol',
  components: {
    SwitchButtons,
    TextField,
    ButtonDefault,
    CoverResult,
  },
  data: () => ({
    title: 'Cobertores térmicos de polietileno',
    options: [
      {
        id: 1,
        value: 'Dimensiones',
        selected: true,
      },
      {
        id: 2,
        value: 'Área',
        selected: false,
      },
    ],
    shapes: [
      {
        id: 1,
        value: 'Rectangular',
        selected: true,
      },
      {
        id: 2,
        value: 'Circular',
        selected: false,
      },
    ],
    inputs: [null, null],
  }),
  computed: {
    ...mapGetters(['includeIGV']),
  },
  methods: {
    ...mapActions(['setDimension']),
    ...mapMutations(['SET_INCLUDE_IGV']),
    /**
     * @param {Number} índice del switch de opciones de cobertor termico
     * @example
     * clickSwitchOptions(0)
     */
    clickSwitchOptions(optionIndex) {
      this.unselectAllSwitch(this.options)
      this.options[optionIndex].selected = true
      if (optionIndex === 1) this.clickSwitchShapes(0)
    },
    /**
     * @param {Number} índice del switch de formas
     * @example
     * clickSwitchShapes(0)
     */
    clickSwitchShapes(optionIndex) {
      this.unselectAllSwitch(this.shapes)
      this.shapes[optionIndex].selected = true
    },
    /**
     * Deselecciona el switch
     * @param {Object[]} switchItems
     * @example
     * unselectAllSwitch([{id:1,selected:true,value:'Item 1'},{id:2,selected:false,value:'Item 2'}])
     */
    unselectAllSwitch(switchItems) {
      switchItems.forEach((option) => {
        option.selected = false
      })
    },
    /**
     * Cambiar el valor de IGV Incluido
     */
    changeIncludeIGV() {
      this.SET_INCLUDE_IGV(!this.includeIGV)
    },
    /**
     * Lógica
     */
    async calcQuotation() {
      // Por Dimensiones
      if (this.options[0].selected) {
        if (this.shapes[0].selected) {
          // Cálculo por un rectangular
          if (!this.inputs[0] || !this.inputs[1]) {
            return this.showAlert(
              'Completa todos los campos y asegúrate que sean numéricos'
            )
          }

          const width = parseFloat(this.inputs[0])
          const height = parseFloat(this.inputs[1])
          const area = width * height
          const justArea = false
          const shape = 'RECT'
          const payload = { width, height, area, justArea, shape }
          await this.setDimension(payload)
        } else if (this.shapes[1].selected) {
          // Cálculo por un circular
          if (!this.inputs[0]) {
            return this.showAlert(
              'Completa todos los campos y asegúrate que sean numéricos'
            )
          }

          const diameter = parseFloat(this.inputs[0])
          const radius = diameter / 2
          const area = Math.PI * Math.pow(radius, 2)
          const justArea = true
          const shape = 'CIRCLE'
          const extra = 0
          const payload = { diameter, area, justArea, shape, extra }
          await this.setDimension(payload)
        }
      }
      // Por Área
      else if (this.options[1].selected) {
        if (!this.inputs[0]) return this.showAlert('Completa todos los campos')

        const area = parseFloat(this.inputs[0])
        const justArea = true
        const shape = 'ANYWAY'
        const payload = { area, justArea, shape }
        await this.setDimension(payload)
      }
    },
    async showAlert(message) {
      alert(message)
    },
  },
}
</script>
<style lang="sass" scoped>
.home
    &-content
        p
            text-align: center
            color: #939393
        .form
            margin: 0 auto
            width: max-content
            .subtitle
                color: #444444
            .textfield
                width: calc(100% - 16px)
            .button-default
                width: 100%
                margin: 28px 0
                // margin: 28px 0 8px 0
            .chk-igv
                margin: 0 auto
                width: fit-content
                margin-bottom: 20px
        .results
            background: #eee
</style>
