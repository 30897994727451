<template>
  <div class="dashboard">
    <!-- Graficos -->
    <Chart />
  </div>
</template>

<script>
// @ is an alias to /src
import Chart from '@/components/dashboard/Chart.vue'

export default {
  name: 'dashboard',
  components: {
    Chart,
  },
}
</script>
