<template>
  <div class="chart">
    <!-- Filtro -->
    <div>
      <span>Año</span>
      <select
        v-model="year"
        @change="
          SET_FILTER_YEAR(year)
          fillData()
        "
      >
        <option value="0">Todos los años</option>
        <option value="2021">2021</option>
        <option value="2020">2020</option>
        <option value="2019">2019</option>
        <option value="2018">2018</option>
        <option value="2017">2017</option>
        <option value="2016">2016</option>
        <option value="2015">2015</option>
      </select>
      <span>Mes</span>
      <select
        v-model="month"
        @change="
          SET_FILTER_MONTH(month)
          fillData()
        "
        disabled="true"
      >
        <option value="0">Todos los meses</option>
        <option value="december">Diciembre</option>
        <option value="november">Noviembre</option>
        <option value="october">Octubre</option>
        <option value="september">Septiembre</option>
        <option value="august">Agosto</option>
        <option value="july">Julio</option>
        <option value="june">Junio</option>
        <option value="may">Mayo</option>
        <option value="april">Abril</option>
        <option value="march">Marzo</option>
        <option value="february">Febrero</option>
        <option value="january">Enero</option>
      </select>
    </div>
    <!-- Grafico -->
    <line-chart :chart-data="datacollection" :styles="myStyles"></line-chart>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapMutations, mapState } from 'vuex'
import LineChart from './LineChart.js'

export default {
  name: 'chart',
  components: {
    LineChart,
  },
  data: () => ({
    datacollection: null,
    year: 2021, // Año por defecto del select
    month: 0, // ïndice de la posiciónn del mes en el select
  }),
  mounted() {
    this.fillData()
  },
  computed: {
    ...mapState(['dashboardData']),
    ...mapGetters(['dataHistorialFiltered']),
    myStyles() {
      return {
        height: 'calc(100vh - 50px)',
        margin: '12px 20px',
        position: 'relative',
      }
    },
    x_labels() {
      if (!this.dataHistorialFiltered) return []
      return this.dataHistorialFiltered.map((data) => data.stringX).reverse()
    },
    y_earnings_values() {
      if (!this.dataHistorialFiltered) return []
      return this.dataHistorialFiltered
        .map((data) => data.earnings.valueY)
        .reverse()
    },
    y_expenses_values() {
      if (!this.dataHistorialFiltered) return []
      return this.dataHistorialFiltered
        .map((data) => data.expenses.valueY)
        .reverse()
    },
    y_job_values() {
      if (!this.dataHistorialFiltered) return []
      return this.dataHistorialFiltered
        .map((data) => data.jobs.valueY)
        .reverse()
    },
  },
  methods: {
    ...mapMutations(['SET_FILTER_YEAR', 'SET_FILTER_MONTH']),
    fillData() {
      this.datacollection = {
        labels: this.x_labels,
        datasets: [
          {
            label: 'Trabajos',
            backgroundColor: '#f6b93b',
            data: this.y_job_values,
          },
          {
            label: 'Gastos',
            backgroundColor: '#74b9ff',
            data: this.y_expenses_values,
          },
          {
            label: 'Ingresos',
            backgroundColor: '#b8e994',
            data: this.y_earnings_values,
          },
        ],
      }
    },
  },
}
</script>
